.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #e3e3e1;
}

.App-link {
  color: #e3e3e1;
}

.App-input {
  color: #e3e3e1;
}

body{
  background-color: #e3e3e1;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.dropdown-menu {
  position: relative;
  display: inline-block;
}

.dropdown-menu button {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 8px 10px;
  cursor: pointer;
}

.dropdown-menu ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0;
  margin: 0;
  display: none;
}

.dropdown-menu ul li {
  padding: 8px 10px;
}

.dropdown-menu ul li:hover {
  background-color: #f2f2f2;
}

.dropdown-menu.show ul {
  display: block;
}

.input-box {
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: all 0.2s;
}

.shake {
  animation: shake 0.2s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

